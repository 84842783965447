import React, { useState } from 'react';
import axios from 'axios';
import '../assets/css/Login-Register.css';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    function handleSubmit(event) {
        event.preventDefault();
        axios.post('https://my-atrun-6ef46bd4415d.herokuapp.com/login', { email, password })
            .then(res => {
                if (res.data === 'no_record') {
                    setError('show');
                }
                else if (res.data.result === 'login_ok') {
                    document.cookie = "nurta=" + res.data.data + "; expires=Session";
                    window.location.href = '/';
                }
            })
            .catch(err => console.log(err));
    }


    return (
        <div className="login-register-area">
            <div className='login-register-div'>
                <form onSubmit={handleSubmit}>
                    <div className='login-header'>Login</div>
                    <div className="group">
                        <input type="email" onChange={e => setEmail(e.target.value)} required></input>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Email</label>
                    </div>
                    <div className="group">
                        <input type="password" onChange={e => setPassword(e.target.value)} required></input>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Passwort</label>
                    </div>
                    <button className="lr-button">Login</button>

                    <div className={'error ' + error}>You have entered an invalid username or password!</div>

                    <div className='login-not-registered'>
                        <span className='login-not-registered-span-left'> NOT YET REGISTERED?</span>
                        <span className='login-not-registered-span-right'>
                            {/* <a className='at-a' href='/register'>REGISTER</a> */}
                            {/* <a className='at-a' onClick={goToRegister} href='#'>REGISTER</a> */}
                            <span className="at-a" onClick={goToRegister}>REGISTER</span>
                        </span>
                    </div>
                </form>
            </div>
        </div >
    )

}

function goToRegister() {
    document.cookie = "nurta=1; expires=Session";
    localStorage.setItem("atrun-route", "register");
    window.location.href = '/';
}

export default Login