import React, { useState } from 'react';
import '../assets/css/Profile.css';
import { useSDK } from "@metamask/sdk-react";

function ProfileHome(props) {
    const [account, setAccount] = useState();
    const { sdk, connected, connecting, provider, chainId } = useSDK();

    const connect = async () => {
        try {
            const accounts = await sdk?.connect();
            setAccount(accounts?.[0]);
        } catch (err) {
            console.warn("failed to connect..", err);
        }
    };

    return (
        <div className="profile-page-container">
            <div className='menu-buttons'>
                <div className='button-area'>
                    <button className="lr-button" onClick={logOut}>Logout</button>
                </div>
                <div className="button-area">

                    <button className="lr-button" onClick={connect}>
                        Connect to metamask
                    </button>
                    
                </div>
            </div>
            {connected && (
                        <div className='wallet-info'>
                            <>
                                {chainId && `Connected chain: ${chainId}`}
                                <p></p>
                                {account && `Connected account: ${account}`}
                            </>
                        </div>
                    )}
            <div className='page-header'>
                <h1>Profile Page </h1>
            </div>
            <div className='page-content'>
                <div className='page-content-line'>Name: {props.name}</div>
                <div className='page-content-line'>Email: {props.email}</div>
            </div>
        </div >
    )
}

function logOut() {
    document.cookie = "nurta=0; expires=Session";
    window.location.href = '/';
}

export default ProfileHome