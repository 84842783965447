import React, { useState } from 'react';
import axios from 'axios';
import '../assets/css/Login-Register.css';

function Register() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    function handleSubmit(event) {
        event.preventDefault();
        axios.post('https://my-atrun-6ef46bd4415d.herokuapp.com/Register', {name, email, password })
            .then(res => {
                if(res.data === 'User exist!'){
                    document.getElementById('registrationOfUserExist').style.display = 'block';
                }
                if(res.data.result === 'register_ok') {
                    document.cookie = "nurta=" + res.data.data + "; expires=Session";
                    localStorage.setItem("atrun-route", "profile");
                    window.location.href = '/';
                }
                console.log(res)
            })
            .catch(err => console.log(err));
    }
    return (
        <div className="login-register-area">
            <div className='login-register-div'>
                <form onSubmit={handleSubmit}>
                    <div className='login-header'>Register</div>
                    <div className="group">
                        <input type="text" onChange={e => setName(e.target.value)} required></input>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Username</label>
                    </div>
                    <div className="group">
                        <input type="email" onChange={e => setEmail(e.target.value)} required></input>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Email</label>
                    </div>
                    <div className="group">
                        <input type="password" onChange={e => setPassword(e.target.value)} required></input>
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Passwort</label>
                    </div>
                    <button className="lr-button">Register</button>
                    <div className='login-not-registered'>
                        <div className='login-not-registered-div'>
                            <span>By registering you confirm that you accept the <a target='_blank' href='https://atrun.io/terms-of-service/'>Terms and Conditions</a></span>
                        </div>
                        <span className='login-not-registered-span-left'>ALREADY REGISTERED?</span>
                        <span className='login-not-registered-span-right'>
                            {/* <a className='at-a' href='/login'>LOG IN</a> */}
                            <span className="at-a" onClick={goToLogin}>LOGIN</span>
                        </span>
                    </div>
                </form>
            </div>
        </div >
    )
    // return (
    //     <div>
    //         <form onSubmit={handleSubmit}>
    //             <div>
    //                 <label htmlFor='email'>Email</label>
    //                 <input type='email' placeholder='Enter Email' onChange={e => setEmail(e.target.value)}></input>
    //             </div>
    //             <div>
    //                 <label htmlFor='password'>Password</label>
    //                 <input type='password' placeholder='Enter Passwort' onChange={e => setPassword(e.target.value)}></input>
    //             </div>
    //             <div id='registrationOfUserExist' className='user-exist'>User Exist.</div>
    //             <button>Register</button>
    //         </form>
    //     </div>
    // )
}

function goToLogin() {
    document.cookie = "nurta=0; expires=Session";
    localStorage.setItem("atrun-route", "login");
    window.location.href = '/';
}

function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);                    

    // hash the message
    const hashBuffer = crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
} 

export default Register