import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../assets/css/Profile.css';
import ProfileHome from './ProfileHome';
import Login from './Login';

function Profile(props, token) {

    const lld = getCookie('nurta');

    const [user, setUser] = useState('');
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            axios.post('https://my-atrun-6ef46bd4415d.herokuapp.com/check', { lld })
                .then(res => {
                    if (res.data.length > 0) {
                        const _u = {
                            name: res.data[0].name,
                            email: res.data[0].email,
                            role: res.data[0].role
                        }
                        setUser(_u);
                    }
                    if (res.data.result === 'login_ok') {
                        document.cookie = "nurta=" + res.data.data + "; expires=Session";
                        // window.location.href = '/profile';
                        <Profile></Profile>
                    }
                    else if (res.data.result === 'no_record') {
                        // window.location.href = '/login';
                        <Login></Login>
                    }
                })
                .catch(err => console.log(err));
        }, [lld, props.id]);

    console.log(user);
    if (user) {

        return (
            <div className="at-header">
                <div className="ct" id="t1">
                    <div className="ct" id="t2">
                        <div className="ct" id="t3">
                            <div className="ct" id="t4">
                                <div className="ct" id="t5">
                                    <ul id="menu">
                                        <a href="#t1"><li className="icon fa fa-bolt" id="uno">Home</li></a>
                                        <a href="#t2"><li className="icon fa fa-keyboard-o" id="dos">Setting</li></a>
                                        {/* <a href="#t3"><li className="icon fa fa-rocket" id="tres">Tab 1</li></a>
                                    <a href="#t4"><li className="icon fa fa-dribbble" id="cuatro">Tab 2</li></a>
                                    <a href="#t5"><li className="icon fa fa-plus-circle" id="cinco">Tab 3</li></a> */}
                                    </ul>
                                    <div className="page" id="p1">

                                        <ProfileHome name={user.name} email={user.email} role={user.role}></ProfileHome>
                                        {/* <section className="icon fa fa-bolt">
                                        <span className="title">Profile</span>
                                        <span className="hint">Like this pen to see the magic!...
                                            Just kidding, it won't happen anything but I'll be really happy If you do so.</span>

                                    </section> */}
                                    </div>
                                    <div className="page" id="p2">
                                        <section className="icon fa fa-keyboard-o"><span className="title">Setting</span></section>
                                    </div>
                                    <div className="page" id="p3">
                                        <section className="icon fa fa-rocket"><span className="title">Rocket</span></section>
                                    </div>
                                    <div className="page" id="p4">
                                        <section className="icon fa fa-dribbble">
                                            <span className="title">Dribbble</span>
                                            <p className="hint">
                                                <a href="https://dribbble.com/hrtzt" target="_blank" rel="noreferrer">Im ready to play, <span className="hint line-trough">invite me </span> find me</a>
                                            </p>
                                            <p className="hint">Already invited by <a href="http://www.dribbble.com/mrpeters" target="_blank" rel="noreferrer">Stan Peters</a></p>
                                        </section>
                                    </div>
                                    <div className="page" id="p5">
                                        <section className="icon fa fa-plus-circle">
                                            <span className="title">More</span>
                                            <p className="hint">
                                                <span>You love one page & CSS only stuff? </span><br />
                                                <a href="https://codepen.io/hrtzt/details/pgXMYb/" target="_blank">check this pen "Pure CSS One page vertical navigation"</a>
                                            </p>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
    else {
        return null;
    }
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default Profile