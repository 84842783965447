import logo from './logo.svg';
import './App.css';
import Login from './components/Login';
import Register from './components/Register';
import Profile from './components/Profile';

function App() {
  const lld = getCookie('nurta');
  const atrunRoute = localStorage.getItem("atrun-route");
  if (lld === '' || lld === '0') {
    localStorage.setItem("atrun-route", "login");
    return (
      <div className="App">
        <Login></Login>
      </div>
    );
  }
  else if(atrunRoute==='register'){
    return (
      <div className="App">
        <Register></Register>
      </div>
    );
  }
  else {
    return (
      <div className="App">
        <Profile></Profile>

        {/* <BrowserRouter>
        <Routes>
            <Route path="/profile" element={<Profile />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
         </Routes>
  
        </BrowserRouter> */}

      </div>
    );

  }
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default App;
